@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");

html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: "Source Sans Pro", sans-serif;
}

.app {
  display: flex;
  position: relative;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #888;
}

/* Handle on Hover */
::-webkit-scrollbar-track:hover {
  background-color: #555;
}

code {
  font-family: "Source Sans Pro", sans-serif;
}

.vh-100 {
  height: 100vh;
}

.cursor-pointer {
  cursor: pointer;
}
