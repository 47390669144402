.signin {
  background-color: #212121;
}

.signin-wrapper {
  position: relative;
  max-width: 430px;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.signin-title {
  font-size: 2.2em;
  position: relative;
  font-weight: 600;
  text-align: center;
}

.input-custom-field {
  position: relative;
  height: 50px;
  width: 100%;
  margin-top: 30px;
}

.input-custom-field input {
  position: absolute;
  height: 100%;
  width: 100%;
  padding: 0 30px;
  border: none;
  outline: none;
  font-size: 1em;
  border-bottom: 2px solid #ccc;
  border-top: 2px solid transparent;
  transition: all 0.3s ease;
}

.input-custom-field input:is(:focus) {
  border-bottom-color: #5f7470;
}

.input-custom-field i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #999;
  font-size: 23px;
}

.input-custom-field input:is(:focus) ~ i {
  color: #5f7470;
}

.input-custom-field i.icon {
  left: 0;
}

.input-custom-field i.showHidePw {
  right: 0;
}
